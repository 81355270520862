import React from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEdit,
  faLock,
  faTrash,
  faEllipsisV,
} from "@fortawesome/fontawesome-free-solid";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";

import { prettyBytes } from "../../helpers";

const createDownloadLink = (document) => {
  const headers = new Headers();
  headers.append(
    "camp_vk_token",
    `Bearer ${localStorage.getItem("camp_vk_token")}`,
  );
  fetch(`/api/documents/read/${document._id}`, { headers })
    .then((res) => res.blob())
    .then((blob) => {
      const objUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = objUrl;
      anchor.download = `${document.name}`;
      anchor.click();
      window.URL.revokeObjectURL(objUrl);
    });
};

const DocumentCard = ({ document, auth, onClickEdit, onClickDelete }) => {
  return (
    <div className="card-wrapper">
      <div className="card-header" style={{ textAlign: "center" }}>
        <FontAwesomeIcon icon={faFileContract} size="lg" />
        {document.is_private && (
          <FontAwesomeIcon
            icon={faLock}
            size="lg"
            style={{ marginLeft: "5px" }}
          />
        )}
        <br />
        <small style={{ whiteSpace: "nowrap" }}>
          {prettyBytes(document.size)}
        </small>
      </div>
      <div className="card-detail">
        <h3>{document.name}</h3>
      </div>
      <DropdownButton title={<FontAwesomeIcon icon={faEllipsisV} />}>
        {auth.role.number == 8000 && (
          <Dropdown.Item onClick={() => onClickEdit(document._id)}>
            <FontAwesomeIcon icon={faEdit} />
            Edit
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => (window.location = document.path)}>
          <FontAwesomeIcon icon={faDownload} />
          Download
        </Dropdown.Item>
        {auth.role.number == 8000 && (
          <Dropdown.Item onClick={() => onClickDelete(document)}>
            <FontAwesomeIcon icon={faTrash} />
            Delete
          </Dropdown.Item>
        )}
      </DropdownButton>
    </div>
  );
};

DocumentCard.propTypes = {
  document: PropTypes.object,
  auth: PropTypes.object,
};

export default DocumentCard;
