import React from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import { UserAvatar } from "../users/UserAvatar";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBook,
  faHandsHelping,
  faEllipsisV,
} from "@fortawesome/fontawesome-free-solid";

const UserCard = ({ user, auth }) => {
  return (
    <LinkContainer to={`/app/directory/${user._id}`}>
      <div className="card-wrapper">
        <div className="card-header">
          <UserAvatar user={user} editable={false} size="sm" />
        </div>
        <div className="card-detail">
          <h3>{user.full_name}</h3>
        </div>
        <DropdownButton
          title={<FontAwesomeIcon icon={faEllipsisV} />}
          onClick={(e) => e.preventDefault()}
        >
          <LinkContainer to={`/app/directory/${user._id}`}>
            <Dropdown.Item>
              <FontAwesomeIcon icon={faUser} size="md" />
              View
            </Dropdown.Item>
          </LinkContainer>
          {auth.role.number > 4000 && (
            <LinkContainer to={`/app/users/${user._id}/courses`}>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faBook} size="md" />
                Courses
              </Dropdown.Item>
            </LinkContainer>
          )}
          {auth.role.number > 4000 && (
            <LinkContainer to={`/app/users/${user._id}/community-service`}>
              <Dropdown.Item>
                <FontAwesomeIcon icon={faHandsHelping} size="md" />
                Community Service
              </Dropdown.Item>
            </LinkContainer>
          )}
        </DropdownButton>
      </div>
    </LinkContainer>
  );
};

export default UserCard;
