import React from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";

import maxBy from "lodash/maxBy";
import startCase from "lodash/startCase";

import moment from "moment";

const MobileGradeList = ({ gradeBreakdown, renderEditable, onClickDelete }) => {
  const columns = [
    {
      Header: "Date",
    },
    {
      Header: "Average",
    },
    {
      Header: "Letter",
    },
    {
      Header: "Tests",
    },
    {
      Header: "Quizzes",
    },
    {
      Header: "Projects",
    },
    {
      Header: "Homework",
    },
    {
      Header: "Classwork",
    },
  ];

  let mostRecentGrades = maxBy(gradeBreakdown, "date") || {};

  return (
    <div className="grades-list">
      {Object.keys(mostRecentGrades)
        .filter((key) => key !== "id" && key !== "date")
        .map((subject) => (
          <li>
            <strong>{startCase(subject)}</strong> : {mostRecentGrades[subject]}
          </li>
        ))}
    </div>
  );
};

MobileGradeList.propTypes = {
  gradeBreakdown: PropTypes.array.isRequired,
  auth: PropTypes.object,
};

export default MobileGradeList;
