import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faTrashAlt,
  faEllipsisV,
  faTable,
  faPlus,
} from "@fortawesome/fontawesome-free-solid";

import CourseProgressList from "./CourseProgressList";
import MobileGradeList from "./MobileGradeList";
import { Row, Button, DropdownButton, Dropdown } from "react-bootstrap";

import { v4 as uuidv4 } from "uuid";

class CourseCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: {},
      data: this.props.categories,
      showTable: false,
      tableEdited: false,
      showAddBtn: true,
    };
  }

  //allow table values to be editable
  renderEditable = (cell) => {
    const changed =
      this.state.changed[`${cell.index} - ${cell.column.id}`] === true;
    return (
      <div
        className={changed ? "edited" : ""}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          let { changed, data } = this.state;

          const cellData = data[cell.index][cell.column.id];

          // Check if cell changed
          changed[`${cell.index} - ${cell.column.id}`] =
            cellData && cellData !== e.target.innerHTML;

          // Set data value
          data[cell.index][cell.column.id] = e.target.innerHTML;

          // Check if value is new to toggle 'changed' class
          let isNew =
            !changed[`${cell.index} - ${cell.column.id}`] &&
            data[cell.index][cell.column.id]
              ? true
              : false;

          this.setState({
            data,
            tableEdited: Object.values(changed).includes(true) || isNew,
            changed,
          });
        }}
      >
        {cell.value}
      </div>
    );
  };

  //on save edited table values
  onSaveEdit = () => {
    let { course } = this.props;
    course.categories = this.state.data;
    this.props.actions.updateGrade(course);
    this.setState({
      tableEdited: false,
      showTable: false,
      changed: {},
      showAddBtn: true,
    });
  };

  onClickViewScores = () => {
    this.setState({
      showForm: false,
      showTable: !this.state.showTable,
    });
  };

  onAddNew = () => {
    let gradeBreakdown = this.state.data || {};
    let newRow = { date: new Date(), id: uuidv4() };
    gradeBreakdown.unshift(newRow);
    this.setState({ data: gradeBreakdown, showAddBtn: false });
  };

  onDeleteRow = (id) => {
    this.setState({
      data: this.state.data.filter((row) => row.id !== id),
      tableEdited: true,
    });
  };

  render() {
    let { course, auth, onCourseDelete, isMobile } = this.props;
    let { showTable, tableEdited, data } = this.state;

    return (
      <>
        <div className={`card-wrapper ${showTable ? "open" : ""}`}>
          <div className="card-header">
            <h3 className="card-title">{course.subject}</h3>
            <h5 className="card-title">{course.instructor}</h5>
          </div>
          <div className="card-detail flex -right" id="courseCardDetails">
            {auth.role.number < 8000 && (
              <FontAwesomeIcon
                icon={showTable ? faChevronUp : faChevronDown}
                onClick={this.onClickViewScores}
              />
            )}

            {/* admin menu options */}
            {auth.role.number > 7000 && (
              <DropdownButton
                title={<FontAwesomeIcon icon={faEllipsisV} />}
                onClick={(e) => e.preventDefault()}
              >
                <Dropdown.Item onClick={this.onClickViewScores}>
                  <FontAwesomeIcon
                    icon={faTable}
                    className="directoryOptions"
                  />
                  {!showTable ? "Show" : "Hide"} Grades
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onCourseDelete(course)}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="directoryOptions"
                  />
                  Delete Course
                </Dropdown.Item>
              </DropdownButton>
            )}
          </div>
        </div>
        <div className="card-collapse">
          {showTable && (
            <>
              {isMobile ? (
                <MobileGradeList gradeBreakdown={data} />
              ) : (
                <>
                  <CourseProgressList
                    gradeBreakdown={data}
                    role={auth.role.number}
                    onClickDelete={this.onDeleteRow}
                    renderEditable={
                      auth.role.number > 7000 ? this.renderEditable : false
                    }
                  />
                  {auth.role.number > 7000 && (
                    <Row className="flex -right btn-row">
                      {tableEdited && (
                        <Button variant="success" onClick={this.onSaveEdit}>
                          Save
                        </Button>
                      )}
                      {this.state.showAddBtn && (
                        <button
                          onClick={this.onAddNew}
                          className="btn"
                          style={{
                            background: "#f15d2a",
                            color: "white !important",
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          Add Averages
                        </button>
                      )}
                    </Row>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

CourseCard.propTypes = {
  actions: PropTypes.object,
  auth: PropTypes.object,
  modal: PropTypes.object,
  user: PropTypes.object,
  grade: PropTypes.object,
  grades: PropTypes.array,
  pathname: PropTypes.string,
  baseUrl: PropTypes.string,
};

export default CourseCard;
