import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faEllipsisV,
  faPencilAlt,
} from "@fortawesome/fontawesome-free-solid";
import ConfirmModal from "../common/ConfirmModal";
import moment from "moment";

class CallCard extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    let {
      call,
      auth,
      onClickEdit,
      onClickDelete,
      modal,
      actions,
      handleCallDelete,
    } = this.props;

    const date = `${call.created_by} \u2022 ${moment(call.date).format(
      "MMM DD, YYYY",
    )}`;

    return (
      <Row id="call-card" className="card-wrapper column">
        <div className="card-header">
          <h3>{call.title}</h3>
          <h6>{date}</h6>
        </div>
        <div className="card-detail">
          <p>{call.description}</p>
        </div>
        <DropdownButton
          title={<FontAwesomeIcon icon={faEllipsisV} />}
          onClick={(e) => e.preventDefault()}
        >
          <Dropdown.Item onClick={() => onClickEdit(call)}>
            <FontAwesomeIcon icon={faPencilAlt} className="directoryOptions" />
            Edit Call
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onClickDelete(call)}>
            <FontAwesomeIcon icon={faTrashAlt} className="directoryOptions" />
            Delete Call
          </Dropdown.Item>
        </DropdownButton>
        <ConfirmModal
          id="callDeleteModal"
          title="Delete Call"
          body="Are you sure you want to delete this call?"
          modal={modal}
          close={actions.hideModal}
          confirm={() => handleCallDelete(call)}
        />
      </Row>
    );
  };
}

CallCard.propTypes = {
  call: PropTypes.object,
  user: PropTypes.object,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  auth: PropTypes.object,
  modal: PropTypes.object,
  actions: PropTypes.object,
};

export default CallCard;
