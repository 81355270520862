import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as userActions from "../../actions/userActions";

import UserCard from "./UserCard";
import BreadCrumbs from "../common/BreadCrumbs";
import Searchbar from "../common/Searchbar";
import { Col } from "react-bootstrap";

import matchSorter from "match-sorter";
import orderBy from "lodash/orderBy";

import Loader from "@/components/common/Loader";

class DirectoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = { users: [], search: "" };
  }

  componentDidMount = async () => {
    let role = this.props.auth.role.number;
    if (role == 4000) {
      await this.props.actions.loadUsersByRole(role);
      this.findCampers();
    } else if (role > 4000) {
      let associations = this.props.auth.user.associated_campers.map(
        (user) => user._id || user,
      );
      this.props.actions.loadUsersByAssociation(associations);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.users !== this.props.users) {
      this.setState({ users: nextProps.users });
    }
  };

  filter = (e) => {
    this.setState({ search: e.target.value });
  };

  findCampers = () => {
    let users = this.state.users;
    let campers = users.filter((users) => users.role.number == 4000);
    this.setState({ users: campers });
  };

  render() {
    let { users, search } = this.state;
    let { auth } = this.props;

    const filteredUsers = users.filter((u) => u._id !== auth.user._id);

    return (
      <>
        <div className="header-wrapper">
          <BreadCrumbs breadcrumbs={[{ label: "Directory" }]} />
          <div>
            <Searchbar onFilter={this.filter} />
          </div>
        </div>
        <div className="content-wrapper transparent">
          <Loader message={`Fetching users...`}>
            <div className="list">
              {orderBy(
                matchSorter(filteredUsers, search, {
                  keys: ["first_name", "last_name", "full_name"],
                }),
                "first_name",
                "asc",
              )
                .filter((user) => user._id !== auth.user._id)
                .map((user, i) => (
                  <UserCard key={i} user={user} auth={auth} />
                ))}
            </div>
          </Loader>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state,
  auth: state.auth,
  users: state.users,
  user: state.user,
  userToDelete: state.userToDelete,
  roles: state.roles,
  modal: state.modal,
  location: state.router.location,
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...userActions }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DirectoryPage);
